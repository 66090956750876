import React from "react"

const GuestBookEntry = ({ author, text }) => (
  <article className="guestbook-item">
    <div className="guestbook-text">{text}</div>
    <div className="guestbook-author">{author}</div>
  </article>
);

const GuestBook = ({ entries }) => (
  <>
    <section className="guestbook">
      <h3>Remembrances</h3>
      {entries.map((entry, i) => <GuestBookEntry {...entry} key={i} />)}
    </section>
    <style jsx>{``}</style>
  </>
);

export default GuestBook;
