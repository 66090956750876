import React from "react";

const SubmissionLinks = () => (
  <div className="submission-links">
    <a href="/submit-guestbook/">Share a remembrance</a> 
     ⬦
    <a href="/submit-photo">Share a photo</a>
  </div>
);

export default SubmissionLinks;