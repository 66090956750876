import React from "react"
import { Helmet } from "react-helmet"
import "../components/layout.css"
import 'typeface-open-sans/index.css'
import Footer from "../components/footer.js"
import NavBar from "../components/navbar.js"
import GuestBook from "../components/guestbook.js"
import MessageListing from "../components/messagelisting.js"
import SubmissionLinks from "../components/submissionlinks.js"
import config from "../config.yaml"


const GuestBookTemplate = ({ pageContext }) => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{config.siteTitle}</title>
        </Helmet>
        <NavBar navLinks={config.navLinks} />
        <SubmissionLinks />
        {pageContext.entries.length && <GuestBook entries={pageContext.entries}/>}
        <SubmissionLinks />
        {pageContext.messageEntries.length && <MessageListing entries={pageContext.messageEntries}/>}
        <Footer/>
    </>
);


export default GuestBookTemplate