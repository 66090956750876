import React from "react"

const Message = ({ author, text, date }) => (
  <article className="messagelisting-item">
    <div className="messagelisting-date">{date}</div>
    <div className="messagelisting-text">{text}</div>
    <div className="messagelisting-author">{author}</div>
  </article>
);

const MessageListing = ({ entries }) => (
  <>
    <section className="messagelisting">
      <h3>Messages from Ros's Final Days</h3>
      {entries.map((entry, i) => <Message {...entry} key={i} />)}
    </section>
    <style jsx>{``}</style>
  </>
);

export default MessageListing;
